import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Import Lightbox styles
import styled from "styled-components";
import { Col } from "react-bootstrap";
import axios from "axios";
import { getSignedUrlLightBox } from "../../api";
import { useTranslation } from "react-i18next";

const MediaGallery = ({ images, showMessage = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxedImage, setLightboxedImage] = useState(null);
  const limit = 12;

  const openLightbox = async (index) => {
    const folder = "pledge_images";
    const filename = images[index].media_url.split("/").pop();
    const signedUrl = await getSignedUrlLightBox(folder, filename);

    // setPhotoIndex(index);
    if (signedUrl) {
      setLightboxedImage(signedUrl);
      // console.log("signedUrl", signedUrl);
    }
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter" || event.key === " ") {
      openLightbox(index);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {images.length > 0 ? (
        <Box
          sx={{
            margin: "3%",
            padding: "1%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FFF",
            borderRadius: "10px",
          }}
        >
          <div className="container-fluid">
            <div className="row">
              {images
                ?.sort((a, b) => a.landing_order - b.landing_order)
                ?.map((data, index) => (
                  <Col
                    xs={6}
                    lg={4}
                    className="hashtagImg_sm"
                    style={{ display: "inline-block" }}
                    key={Math.random()}
                  >
                    <div
                      style={{
                        borderRadius: "15px",
                        position: "relative",
                        overflow: "hidden",
                        margin: "10px 0px",
                      }}
                      onClick={() => {
                        openLightbox(index);
                      }}
                    >
                      <VillageImageDiv
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "15px",
                          backgroundSize: "cover",
                          filter: "blur(40px)",
                          height: "18vh",
                          // border: '1px solid black',
                          overflow: "hidden",
                          backgroundImage: `url(${data.thumbnail_url[0]})`,
                          cursor: "pointer",
                        }}
                      />
                      <VillageImageDiv
                        className="th-border-top th-border-bottom"
                        style={{
                          borderRadius: "15px",
                          position: "absolute",
                          top: "0%",
                          right: "0%",
                          // border: '2px solid transparent',
                          bottom: "0%",
                          backgroundSize: "contain",
                          backgroundImage: `url(${data.thumbnail_url[0]})`,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Col>
                ))}
            </div>
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={lightboxedImage.data}
              // nextSrc={images[(photoIndex + 1) % images.length]?.thumbnail_url[0]}
              // prevSrc={
              //   images[(photoIndex + images.length - 1) % images.length]
              //     ?.thumbnail_url[0]
              // }
              onCloseRequest={closeLightbox}
              // onMovePrevRequest={() =>
              //   setPhotoIndex((photoIndex + images.length - 1) % images.length)
              // }
              // onMoveNextRequest={() =>
              //   setPhotoIndex((photoIndex + 1) % images.length)
              // }
            />
          )}
        </Box>
      ) : (
        <>
          {showMessage ? (
            <Box
              sx={{
                textAlign: "center",
                height: "50vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4">{t("no_image")}</Typography>
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

export default MediaGallery;

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;

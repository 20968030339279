import React from "react";
import Navbar from "../../layouts/full-layout/header/homeHeader";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Footer from "../../layouts/full-layout/footer/Footer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import arrow from "./../../assets/images/logos/arrow.svg";

const Arrow = () => {
  return <img src={arrow} alt="" style={{ width: "20px", height: "20px" }} />;
};

export default function About() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;
  return (
    <>
      <Navbar />
      <div
        className="container-fluid text-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflowX: "hidden",
          position: "relative",
          marginTop: screenWidth > 1000 ? "70px" : "50px",
          height: "100vh",
          backgroundImage:
            screenWidth > 1000
              ? `url(./asset/images/about_bg.png)`
              : `url(./asset/images/about_mob_bg.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="container"
          style={{
            overflow: "hidden",
          }}
        >
          <div className="row p-3">
            <h2 className="about_main_title">{t("about_heading")}</h2>
            <p className="about_main_para pt-3">{t("about_para")}</p>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row text-center my-5">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src="./asset/images/left.svg"
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
            <h3 className="people_title px-2">{t("people_movement")}</h3>
            <img
              src="./asset/images/right.svg"
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
          </div>

          <p className="people_para">{t("people_movement_para")}</p>
        </div>
        <div>
          <h3 className="text-center people_title my-4">
            {t("earlier_editions")}
          </h3>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div>
              <div className="about_img">
                <img src="./asset/images/about2.png" alt="" />
              </div>
              <div>
                <div className="about_clickable my-2">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Arrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography className="about_card_text">
                        {t("about_col1_1")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a
                          href="https://www.pib.gov.in/PressReleseDetail.aspx?PRID=1947521"
                          target="_blank"
                        >
                          https://www.pib.gov.in/PressReleseDetail.aspx?PRID=1947521
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="about_clickable my-2">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Arrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography className="about_card_text">
                        {t("about_col1_2")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a
                          href="https://pib.gov.in/PressReleseDetailm.aspx?PRID=1944728"
                          target="_blank"
                        >
                          https://pib.gov.in/PressReleseDetailm.aspx?PRID=1944728
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="about_clickable my-2">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Arrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography className="about_card_text">
                        {t("about_col1_3")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a
                          href="https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1947521#:~:text='Har%20Ghar%20Tiranga'%20campaign%20was,of%20India%20in%20their%20homes"
                          target="_blank"
                        >
                          Ministry of Culture to Organise 'HAR Ghar Tiranga'
                          Bike Rally of MPs in Delhi Tomorrow
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="about_img">
              <img src="./asset/images/about1.jpg" alt="" />
            </div>

            <div>
              <div className="about_clickable my-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Arrow />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="about_card_text">
                      {t("about_col2_1")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <a
                        href="https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1852264"
                        target="_blank"
                      >
                        https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1852264
                      </a>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="about_clickable my-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Arrow />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="about_card_text">
                      {t("about_col2_2")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <a
                        href="https://pib.gov.in/PressReleasePage.aspx?PRID=1852066"
                        target="_blank"
                      >
                        https://pib.gov.in/PressReleasePage.aspx?PRID=1852066
                      </a>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="about_clickable my-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Arrow />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="about_card_text">
                      {t("about_col2_3")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <a
                        href="https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1844020"
                        target="_blank"
                      >
                        https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1844020
                      </a>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflowX: "hidden",
          position: "relative",
          marginTop: screenWidth > 1000 ? "70px" : "50px",
          height: screenWidth > 500 ? "50vh" : "40vh",
          backgroundImage:
            screenWidth > 500
              ? `url(./asset/images/about_animation.png)`
              : `url(./asset/images/about_animation_mob.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      ></div>

      <div className="container mt-5">
        <div className="row text-center align-items-center about_card">
          {screenWidth < 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "16px 16px 0px 0px",
                  }}
                  src="./asset/images/a1.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_yatra")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <p className="about_card_para">{t("tiranga_yatra_para")}</p>
          </div>
          {screenWidth > 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "0px 16px 16px 0px",
                  }}
                  src="./asset/images/a1.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>

        <div className="row text-center align-items-center about_card">
          <div className="col-lg-6 p-0">
            <div className="about_img">
              <img
                style={{
                  borderRadius:
                    screenWidth > 1000
                      ? "16px 0px 0px 16px"
                      : "16px 16px 0px 0px",
                }}
                src="./asset/images/a2.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_rallies")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_rallies_para")}</p>
          </div>
        </div>

        <div className="row text-center align-items-center about_card">
          {screenWidth < 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "16px 16px 0px 0px",
                  }}
                  src="./asset/images/a3.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_runs")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_runs_para")}</p>
          </div>

          {screenWidth > 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "0px 16px 16px 0px",
                  }}
                  src="./asset/images/a3.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>

        <div className="row text-center  align-items-center about_card">
          <div className="col-lg-6 p-0">
            <div className="about_img">
              <img
                style={{
                  borderRadius:
                    screenWidth > 1000
                      ? "16px 0px 0px 16px"
                      : "16px 16px 0px 0px",
                }}
                src="./asset/images/a4.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_concert")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_concert_para")}</p>
          </div>
        </div>

        <div className="row text-center  align-items-center about_card">
          {screenWidth < 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "16px 16px 0px 0px",
                  }}
                  src="./asset/images/a5.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_canvas")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_canvas_para")}</p>
          </div>

          {screenWidth > 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "0px 16px 16px 0px",
                  }}
                  src="./asset/images/a5.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>

        {/* <div className="row text-center  align-items-center about_card">
          <div className="col-lg-6 p-0">
            <div className="about_img">
              <img src="./asset/images/a6.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_pledge")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_pledge_para")}</p>
          </div>
        </div> */}

        <div className="row text-center align-items-center about_card">
          {/* {screenWidth < 1000 && ( */}
          <div className="col-lg-6 p-0">
            <div className="about_img">
              <img
                style={{
                  borderRadius:
                    screenWidth > 1000
                      ? "16px 0px 0px 16px"
                      : "16px 16px 0px 0px",
                }}
                src="./asset/images/a7.jpg"
                alt=""
              />
            </div>
          </div>
          {/* )} */}
          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_selfie")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_selfie_para")}</p>
          </div>
          {/* {screenWidth > 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img src="./asset/images/a7.jpg" alt="" />
              </div>
            </div>
          )} */}
        </div>

        <div className="row text-center align-items-center about_card">
          {screenWidth < 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "16px 16px 0px 0px",
                  }}
                  src="./asset/images/a8.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_tribute")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_tribute_para")}</p>
          </div>
          {screenWidth > 1000 && (
            <div className="col-lg-6 p-0">
              <div className="about_img">
                <img
                  style={{
                    borderRadius: "0px 16px 16px 0px",
                  }}
                  src="./asset/images/a8.jpg"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>

        <div className="row text-center  align-items-center about_card">
          {/* {screenWidth < 1000 && ( */}
          <div className="col-lg-6 p-0">
            <div className="about_img">
              <img
                style={{
                  borderRadius:
                    screenWidth > 1000
                      ? "16px 0px 0px 16px"
                      : "16px 16px 0px 0px",
                }}
                src="./asset/images/a9.jpg"
                alt=""
              />
            </div>
          </div>
          {/* )} */}
          <div className="col-lg-6 px-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="./asset/images/left.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="about_card_title">{t("tiranga_melas")}</h3>
              <img
                src="./asset/images/right.svg"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <p className="about_card_para">{t("tiranga_melas_para")}</p>
          </div>
          {/* {screenWidth > 1000 && ( */}
          {/* <div className="col-lg-6 p-0">
            <div className="about_img">
              <img src="./asset/images/a9.jpg" alt="" />
            </div>
          </div> */}
          {/* )} */}
        </div>
      </div>

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "20px",
          width: "100%",
        }}
      >
        <LoadingButton
          className="orange_btn"
          variant="contained"
          type=""
          sx={{
            "& .MuiLoadingButton-loadingIndicator": {
              color: "white",
            },
          }}
          onClick={() => {
            navigate("/pledge");
          }}
        >
          {t("upload_selfie_button_text")}
        </LoadingButton>
      </Box> */}

      <Footer />
    </>
  );
}

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
const { getFunctions } = require("firebase/functions");
const firebaseConfig = {
  apiKey: "AIzaSyCYKP9JoR-Okmme0pgh2tlHXwkC0gc8UqE",
  authDomain: "har-ghar-tiranga-2024.firebaseapp.com",
  projectId: "har-ghar-tiranga-2024",
  storageBucket: "har-ghar-tiranga-2024.appspot.com",
  messagingSenderId: "435679349953",
  appId: "1:435679349953:web:3a085098dcbb19786a5840",
  measurementId: "G-LZTC0STT9T",
};
const app = firebase.initializeApp(firebaseConfig);
firebase.database();
firebase.storage();
firebase.analytics();
firebase.firestore();
export const db = getFirestore(app);
export const firestore = firebase.firestore();
export const functions = getFunctions(app);
export const auth = getAuth(app);

export default firebase;

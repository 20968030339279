import axios from "axios";
import { getAllEventTypes, getCurrentToken } from "../firebase/functions";

const BASE_URL =
  "https://asia-south1-har-ghar-tiranga-2024.cloudfunctions.net/";

axios.defaults.baseURL = BASE_URL;

export const resetPassword = async (payload) => {
  try {
    return await axios.post(`updatePassword`, payload);
  } catch (err) {
    console.log(err);
  }
};

export const updatePermissions = async (payload) => {
  try {
    return await axios.post("addAndUpdatePermission", payload);
  } catch (err) {
    console.log(err);
  }
};

export const addRole = async (payload) => {
  try {
    return await axios.post("addRole", payload);
  } catch (err) {
    console.log(err);
  }
};

export const createUser = async (payload) => {
  const token = await getCurrentToken();
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    return await axios.post("userCreation", payload, { headers });
  } catch (err) {
    console.log(err);
  }
};

export const createCityReport = async (payload) => {
  const token = await getCurrentToken();
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    return await axios.post("createCityReport", payload, { headers });
  } catch (err) {
    console.log(err);
  }
};

export const addEvent = async (payload) => {
  const token = await getCurrentToken();
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return await axios.post("createEvent", payload, { headers });
  } catch (err) {
    console.log(err);
  }
};

export const updateEvent = async (payload) => {
  try {
    return await axios.post("updateEvent", payload);
  } catch (err) {
    console.log(err);
  }
};

export const getPresignedUrl = async (filename, foldername) => {
  try {
    return await axios.post(
      `getSignedUrl?folder=${foldername}&name=${filename}&action=write`
    );
  } catch (err) {
    console.log(err);
  }
};

export const createCityReportActivity = async (payload) => {
  try {
    return await axios.post("createCityReportActivity", payload);
  } catch (e) {
    console.log(e);
  }
};

export const getSignedUrl = async (folder, name) => {
  try {
    return await axios.post(
      `getSignedUrl?folder=${folder}&name=${name}&action=read`
    );
  } catch (e) {
    console.log(e);
  }
};

export const createEventActivity = async (payload) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    return await axios.post("createPledgeMedia", payload, { headers });
  } catch (e) {
    console.log(e);
  }
};

export const getMapDetails = async () => {
  try {
    return await axios.get(
      "getCachedResult?url=https://asia-south1-har-ghar-tiranga-2024.cloudfunctions.net/getRealTimeRepoet"
    );
  } catch (e) {
    console.log(e);
  }
};

export const getMediaImages = async (
  collectionName,
  page,
  landing_page,
  limit,
  phone
) => {
  try {
    if (landing_page) {
      return await axios.get(
        `getContentPaginated?collection_name=${collectionName}&page=${page}&limit=${limit}&landing_page=${landing_page}&size=300x300`
      );
    } else {
      if (phone) {
        return await axios.get(
          `getContentPaginated?collection_name=${collectionName}&page=${page}&limit=10&size=300x300&phone=${encodeURI(phone)}`
        );
      } else {
        return await axios.get(
          `getContentPaginated?collection_name=${collectionName}&page=${page}&limit=10&size=300x300`
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
};
export const getSignedUrlLightBox = async (folder, name) => {
  try {
    return await axios.get(
      `getSignedUrl?folder=${folder}&name=${name}&action=read`
    );
  } catch (e) {
    console.log(e);
  }
};

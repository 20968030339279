export const ROLES = "role_master";
export const PERMISSIONS = "permission";
export const USERS = "users";
export const CITY_REPORTS = "city_reports";
export const USERS_DETAILS = "user_details";
export const MINISTRIES = "ministry_master";
export const EVENT_TYPE = "event_type_master";
export const COMPONENT = "components";
export const DISTRICT_MASTER = "district_state_master";
export const EVENTS = "events";
export const CITY_REPORTS_ACTIVITY = "city_reports_activity";
export const STATE_MASTER = "state_master";
export const ULB_MASTER = "district_ulb_master";
export const BLOCK_MASTER = "district_block_master";
export const MINISTRY_UNIT_MASTER = "ministry_unit_master";
export const MINISTRY_SUB_UNIT_MASTER = "ministry_sub_unit_master";
export const LOCATION_TYPES = [
  { label: "Urban", value: "URBAN" },
  { label: "Rural", value: "RURAL" },
];
export const MAP_DETAILS = "apiCache";
export const PLEDGE_MEDIA = "pledge_media";
export const PLEDGE = "pledge";
export const MODERATED_PLEDGE_MEDIA = "moderated_pledge_media";
export const TWITTER_PUBLIC_FEED = "twitterpublicfeed";

import React from "react";
import { useTranslation, Trans } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // const [screenSize, setScreenSize] = React.useState('');
  // React.useEffect(() => {
  //   setScreenSize(window.innerWidth);
  // }, []);
  const [currentLang, setCurrentLang] = React.useState(
    i18n.language ? i18n.language : "en"
  );
  React.useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  return (
    <footer
      className="footer"
      id="footer"
      style={{
        cursor: "default",
        padding: 0,
      }}
    >
      <div className="container-fluid">
        <div>
          {" "}
          {/* className={classNames('level', classes.level)} */}
          {/* <div className="p-2">
            <span className="level-item">{t('footer')} </span>
          </div> */}
          {/* <div className="container-fluid d-flex justify-content-center">
            <img src="./images/footer_hgt.png" alt="harghartiranga" />
          </div> */}
          {/* <div className={classNames('level-right', classes.levelRight)}>
            <div className="level-item">
              <div className="logo">
                <a href="">
                  <img
                    src=""
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="text-center">
        <p
          style={{
            fontSize: "0.8rem",
            color: "grey",
          }}
        >
          {t("content_available")}
        </p>
      </div>
      <div className="container-fluid">
        <div className="row d-flex align-items-center">
          <div className={window.innerWidth > 768 ? "col-5" : "col-4"}>
            <hr className="org" />
            <hr className="grn" />
          </div>
          <div
            className={`${window.innerWidth > 768 ? "col-2" : "col-4"} text-center text-nowrap`}
            style={{ padding: "0" }}
          >
            <div className="row">
              <div
                className="p-2 text-break footer_text d-flex align-items-center text-bold  justify-content-center  col  "
                // className={
                //   screenSize > '600px'
                //     ? 'p-2 text-break footer_text d-flex align-items-center text-bold  justify-content-center  col-6 '
                //     : 'p-2 text-break footer_text d-flex align-items-center text-bold  justify-content-center  col  '
                // }

                style={{
                  fontWeight: "bold",
                  fontSize: window.innerWidth > 768 && "24px",
                }}
              >
                harghartiranga.com
              </div>

              {/* <div
                className={
                  screenSize > 600
                    ? 'p-2 text-break sm_font d-flex align-items-center justify-content-center text-bold  col-6'
                    : 'display_hide'
                }
              >
               <a href="/">
                  <img
                    className="img-fluid"
                    style={{ height: '3vh' }}
                    src="./images/facebook.png"
                    alt="facebook"
                  />
                </a>
                                <a href="/">
                  <img
                    className="img-fluid px-2"
                    style={{ height: '3vh' }}
                    src="./images/instagram.png"
                    alt="facebook"
                  />
                </a>
                <a href="/">
                  <img
                    className="img-fluid"
                    style={{ height: '3vh' }}
                    src="./images/twitter.png"
                    alt="facebook"
                  />
                </a> 
                <span>
                  <FacebookShareButton
                    url="harghartiranga.com"
                    quote="Let's come forward and contribute our part in pinning our Indian flag and putting a selfie with it."
                    hashtag="harghartiranga"
                  >
                    <FacebookIcon size="1.7rem" round />
                  </FacebookShareButton>
                </span>
                <span className="px-2">
                  <TwitterShareButton
                    title="Let's come forward and contribute our part in pinning our Indian flag and putting a selfie with it."
                    url="harghartiranga.com"
                    hashtags={['harghartiranga']}
                  >
                    <TwitterIcon size="1.7rem" round />
                  </TwitterShareButton>
                </span>
                <span>
                  <WhatsappShareButton
                    title="Let's come forward and contribute our part in pinning our Indian flag and putting a selfie with it."
                    url="harghartiranga.com"
                    hashtags={['harghartiranga']}
                  >
                    <WhatsappIcon size="1.7rem" round />
                  </WhatsappShareButton>
                </span>
              </div> */}
            </div>
          </div>
          <div className={window.innerWidth > 768 ? "col-5" : "col-4"}>
            <hr className="org" />
            <hr className="grn" />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center container">
        <div className="row">
          <div className="col-5">
            <a
              className={
                currentLang.startsWith("mal")
                  ? "p-2 text-break sm_font d-flex align-items-center"
                  : "p-2 text-nowrap sm_font d-flex align-items-center  justify-content-center"
              }
              href="/terms-and-conditions"
            >
              {t("terms_and_condition")}
            </a>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-center">
            |
          </div>
          <div className="col-5">
            <a
              className={
                currentLang.startsWith("mal")
                  ? "p-2 text-break sm_font d-flex align-items-center"
                  : "p-2 text-nowrap sm_font d-flex align-items-center  justify-content-center"
              }
              href="/privacy-policy"
            >
              {t("privacy_policy_title")}
            </a>
          </div>
        </div>

        <br />
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import Navbar from "../../layouts/full-layout/header/homeHeader";
import Footer from "../../layouts/full-layout/footer/Footer";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Grid,
  Card,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const DownloadButton = ({ englishLink, hindiLink, isPsd, isVideo }) => {
  const { t } = useTranslation();

  const downloadFile = async (url) => {
    try {
      const response = await fetch(url, { method: "GET" });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "file.png";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleDownload = (url, isPsd, isVideo) => {
    if (isPsd || isVideo) {
      window.open(url, "_blank");
    } else {
      downloadFile(url);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, mt: 2 }}>
      {englishLink && !isVideo && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "#FF5C00" }}
          onClick={() => handleDownload(englishLink, isPsd)}
        >
          {isPsd ? t("download_psd") : t("download_media")}
        </Button>
      )}
      {hindiLink && !isVideo && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "#FF5C00" }}
          onClick={() => handleDownload(hindiLink, isPsd)}
        >
          {isPsd ? t("download_psd") : t("download_media")}
        </Button>
      )}
      {isVideo && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "#FF5C00" }}
          onClick={() => handleDownload(hindiLink, isPsd, true)}
        >
          {t("download_mp4")}
        </Button>
      )}
    </Box>
  );
};

const Downloads = () => {
  const { t } = useTranslation();

  const [playingVideo, setPlayingVideo] = useState(null);
  const screenWidth = window.innerWidth;

  const handlePlayClick = (videoSrc) => {
    setPlayingVideo(videoSrc);
  };

  const bannerSection = (
    mediaType,
    mediaSrc,
    altText,
    bannerName,
    imageLink,
    psdLink,
    mediaWidth,
    gridItem
  ) => (
    <Grid item xs={12} md={gridItem ? gridItem : 6}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {mediaType === "image" ? (
          <img
            src={mediaSrc}
            alt={altText}
            style={{
              width: mediaWidth ? mediaWidth : "100%",
              overflow: "hidden",
            }}
          />
        ) : (
          <>
            {playingVideo !== mediaSrc ? (
              <div style={{ position: "relative", width: "70%" }}>
                <img
                  src={altText}
                  style={{ width: "100%", cursor: "pointer", display: "block" }}
                />
                <IconButton
                  onClick={() => handlePlayClick(mediaSrc)}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    bgcolor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": {
                      bgcolor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  <PlayArrowIcon fontSize="large" />
                </IconButton>
              </div>
            ) : (
              <video controls style={{ width: "70%" }} autoPlay>
                <source src={mediaSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {bannerName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {(imageLink.english || imageLink.hindi) && (
            <DownloadButton
              englishLink={imageLink.english}
              hindiLink={imageLink.hindi}
              isPsd={false}
              isVideo={mediaType === "video"}
            />
          )}
          {(psdLink.english || psdLink.hindi) && (
            <DownloadButton
              englishLink={psdLink.english}
              hindiLink={psdLink.hindi}
              isPsd={true}
            />
          )}
        </Box>
      </Box>
    </Grid>
  );

  const handleDownloadQR = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "qrcode.png"; // You can change the file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadPdf = (url) => {
    window.open(url, "_blank");
  };

  const handleDownloadCdr = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Navbar />
      <Box sx={{ marginTop: "64px", padding: { xs: "2%", md: "4%" } }}>
        <Box
          sx={{
            padding: {
              // xs: "2%",
              md: "4%",
              justifyContent: "center",
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
          >
            {t("curtain_raiser")}
          </Typography>
          <Card>
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "flex-end",
                // p: 2,
                pt: 4,
              }}
            >
              <Grid item sm={12} md={6}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/SQExdpGFrJk?si=5hkC_VljgN7OYOSO"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={t("qr_image")}
                    style={{ width: window.innerWidth > 768 ? "25%" : "50%" }}
                  />
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#FF5C00", width: "40%" }}
                    onClick={() => handleDownloadQR("/asset/images/qr_en.png")}
                  >
                    {t("download_qr_en")}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#FF5C00", width: "40%" }}
                    onClick={() => handleDownloadQR("/asset/images/qr_hin.png")}
                  >
                    {t("download_qr_hin")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("banner")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: "flex-end", alignItems: "flex-end" }}
          >
            {bannerSection(
              "image",
              "/asset/images/social1.png",
              "FB_BANNER",
              t("facebook_banner"),
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FSM_Banner%2FHGT_Facebook_Banner.png?alt=media&token=3062f6bf-af7b-4159-8e32-08e774f19928",
              },
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FSM_Banner%2FHGT_Facebook_Banner.psd?alt=media&token=b2d09e7b-7f4c-47fe-8825-d3fc63bd5cd0",
              }
            )}
            {bannerSection(
              "image",
              "/asset/images/social2.png",
              "TW_BANNER",
              t("twitter_banner"),
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FSM_Banner%2FHGT_Twitter_Banner.png?alt=media&token=a986ec18-0e21-4c88-9b8e-d47c94715d91",
              },
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FSM_Banner%2FHGT_Twitter_Banner.psd?alt=media&token=8c10c359-9c19-430a-a21f-bca08a1404ac",
              }
            )}
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("blank_canvas")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: "center", alignItems: "flex-end" }}
          >
            {bannerSection(
              "image",
              "/asset/images/canvas.png",
              "BLANK_CANVAS",
              t("blank_canvas"),
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fblank_canvas%2FHGT_BlankCanves.png?alt=media&token=01b0b55e-321d-42b0-89c8-f1ed30a9d1b9",
              },
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fblank_canvas%2FHGT_BlankCanves.psd?alt=media&token=5a50c3fc-74e0-4af3-b5c6-a0ad91119679",
              },
              "70%"
            )}
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("hoarding")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: "center", alignItems: "flex-end" }}
          >
            {bannerSection(
              "image",
              "/asset/images/hoarding.png",
              "HGT_Hoarding",
              t("hoarding"),
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FHoarding%2FHGT_HoardingENG.png?alt=media&token=ed2ab844-ef5b-4ab3-ad57-a81a6dd6ac7f",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FHoarding%2FHGT_HoardingHIN.png?alt=media&token=7346ca9c-647c-4f80-b426-3b99da328a22",
              },
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FHoarding%2FHGT_HoardingENG.psd?alt=media&token=d0c8e4e5-2139-4eac-8b61-0d40e8ee849f",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FHoarding%2FHGT_HoardingHIN.psd?alt=media&token=44009a52-6675-45b5-ac42-5014b2ce10c5",
              }
            )}
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("smc")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: "center", alignItems: "flex-end" }}
          >
            {bannerSection(
              "image",
              "/asset/images/smc1.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F2.png?alt=media&token=8c919eef-d2f2-4dbc-a4e1-d80b1c9c4df2",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "55%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/smc2.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2FPM.jpeg?alt=media&token=fde19bc6-1ee3-4a0e-9959-a4e0062d597c",
              },
              {
                english: "",
                hindi: "",
              },
              "",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/smc3.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F5.jpeg?alt=media&token=7d17e3b9-1714-4b27-b2e0-0eddcecccc4e",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/smc4.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F6.jpeg?alt=media&token=5aa1de26-abf9-4fb6-80a2-1ac65cfaac03",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/smc5.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F8.jpeg?alt=media&token=3a5a87ac-8dd6-4699-83e5-680bec722bce",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/smc6.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F9.jpeg?alt=media&token=3aa8edd9-883c-4dd9-9529-dcfd102a4a3f",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F1.mp4?alt=media&token=02c069b5-6077-4792-83cf-9a553b73a30b",
              "/asset/images/1.png",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F1.mp4?alt=media&token=02c069b5-6077-4792-83cf-9a553b73a30b",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              }
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F3.mp4?alt=media&token=8c1a9cd2-2296-47e2-8a9c-d09963ae6f33",
              "/asset/images/3.png",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F3.mp4?alt=media&token=8c1a9cd2-2296-47e2-8a9c-d09963ae6f33",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              }
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F4.mp4?alt=media&token=ffa27ab8-cefa-42dd-a01d-7e1d8efb7ff2",
              "/asset/images/4.png",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F4.mp4?alt=media&token=ffa27ab8-cefa-42dd-a01d-7e1d8efb7ff2",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              }
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F7.mp4?alt=media&token=f2c46a63-e504-4332-9ad8-fed2d7c9b867",
              "/asset/images/7.png",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fsm_creatives%2F7.mp4?alt=media&token=f2c46a63-e504-4332-9ad8-fed2d7c9b867",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              }
            )}
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("standee")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: "center", alignItems: "flex-end" }}
          >
            {bannerSection(
              "image",
              "/asset/images/standee.png",
              "hoarding",
              t("standee"),
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FStandee%2FHGT_Standee-1ENG.png?alt=media&token=0ba79a03-373b-409a-8dfe-6ccb16dc505d",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FStandee%2FHGT_Standee-1HIN.png?alt=media&token=066686c8-163a-4cf1-8ddd-2a354a0a4386",
              },
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FStandee%2FHGT_Standee-1ENG.psd?alt=media&token=1f0ffea5-1a9e-4803-98d3-c7bf8c448956",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FStandee%2FHGT_Standee-1HIN.psd?alt=media&token=3d194603-1afc-4b24-b906-a8f3c345eca0",
              },
              "45%"
            )}
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("selfie_booth")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: "center", alignItems: "flex-end" }}
          >
            {bannerSection(
              "image",
              "/asset/images/selfie.png",
              "selfie",
              t("selfie_booth"),
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fselfie_booth%2FPNG1-Eng-01.png?alt=media&token=07595126-2075-4ebb-afb9-0af87dcfadd5",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fselfie_booth%2FPNG1-Hindi-02.png?alt=media&token=681d1a01-e58a-461f-b6b1-28a4c3aabd3f",
              },
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FHGT_Selfie Booth_Eng (1).ai?alt=media&token=f322663a-762b-4c96-a3a0-01b5afdff360",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2FHGT_Selfie Booth_Hindi.ai?alt=media&token=4a2a45f3-1e8f-4fe8-9880-7c83f7c66fe7",
              },
              "45%"
            )}
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("did_you_know")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: "center", alignItems: "flex-end" }}
          >
            {bannerSection(
              "image",
              "/asset/images/d1.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F1.jpeg?alt=media&token=a0c8d523-1c82-4f77-b079-4d20a349a5b7",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/d2.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F2.jpeg?alt=media&token=3c432bbb-97f5-46a5-b593-337362b9e92f",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/d3.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F3.jpeg?alt=media&token=75cba373-8a96-4d19-b397-2bb5c44d53f1",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/d4.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F4.jpeg?alt=media&token=3524d8b6-678b-44a5-b93b-91622dae5ef1",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/d5.png",
              "HGT_Hoarding",
              "",
              {
                english: "",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F5.jpeg?alt=media&token=26b30a1f-0215-4feb-b3a7-f004b14d06fa",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "image",
              "/asset/images/d6.png",
              "HGT_Hoarding",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F6.jpeg?alt=media&token=dc81aa83-fc98-4a27-a557-089bf3c12d71",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              },
              "70%",
              4
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F1.mp4?alt=media&token=85be83ad-95f9-43cc-86f5-b862fa396f56",
              "/asset/images/1.png",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2F1.mp4?alt=media&token=85be83ad-95f9-43cc-86f5-b862fa396f56",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              }
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2Fcorrect2.mp4?alt=media&token=81f4f067-6fcf-4e9f-8358-9397bab1c35a",
              "/asset/images/v2.png",
              "",
              {
                english: "",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2Fcorrect2.mp4?alt=media&token=81f4f067-6fcf-4e9f-8358-9397bab1c35a",
              },
              {
                english: "",
                hindi: "",
              }
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2Fhow1.mp4?alt=media&token=1c2802e6-5ba9-40ef-8f6c-b1d39fe5cbf4",
              "/asset/images/v3.png",
              "",
              {
                english: "",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2Fhow1.mp4?alt=media&token=1c2802e6-5ba9-40ef-8f6c-b1d39fe5cbf4",
              },
              {
                english: "",
                hindi: "",
              }
            )}
            {/* {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2Fhow1.mp4?alt=media&token=1c2802e6-5ba9-40ef-8f6c-b1d39fe5cbf4",
              "/asset/images/7.png",
              "",
              {
                english:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fdid_you_know%2Fhow1.mp4?alt=media&token=1c2802e6-5ba9-40ef-8f6c-b1d39fe5cbf4",
                hindi: "",
              },
              {
                english: "",
                hindi: "",
              }
            )} */}
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("tiranga_anthem")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fcoming_soon%2FHar%20Ghar%20Tiranga%20Anthem_9%20Aug%20Final.mp4?alt=media&token=bbd7a557-38e2-4db8-a9b5-9ac8a62b924d",
              "/asset/images/tiranga_anthem.png",
              "",
              {
                english: "",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fcoming_soon%2FHar%20Ghar%20Tiranga%20Anthem_9%20Aug%20Final.mp4?alt=media&token=bbd7a557-38e2-4db8-a9b5-9ac8a62b924d",
              },
              {
                english: "",
              }
            )}
          </Grid>
        </Card>
      </Box>
      <Box
        sx={{
          padding: {
            // xs: "2%",
            md: "4%",
            justifyContent: "center",
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("tiranga_exhibition")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "flex-end",
              // p: 2,
              pt: 4,
            }}
          >
            <Grid item sm={12} md={6}>
              <img
                src="/asset/images/tiranga_exhibition.jpeg"
                style={{
                  width: "60%",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "3%",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#FF5C00" }}
                    onClick={() =>
                      handleDownloadPdf(
                        "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Ftiranga_exhibition%2Fenglish.pdf?alt=media&token=84093d94-0166-4431-8112-38fe6a8db7ae"
                      )
                    }
                  >
                    {t("download_pdf_eng")}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#FF5C00" }}
                    onClick={() =>
                      handleDownloadPdf(
                        "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Ftiranga_exhibition%2FHINDI.pdf?alt=media&token=02aaf63f-56f4-4f21-ab9c-1cf618061c6c"
                      )
                    }
                  >
                    {t("download_pdf_hin")}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#FF5C00" }}
                    onClick={() =>
                      handleDownloadCdr(
                        "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Ftiranga_exhibition%2Fenglish.cdr?alt=media&token=2ad076c0-f156-4adc-8ad9-716905a0900a"
                      )
                    }
                  >
                    {t("download_open_english")}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#FF5C00" }}
                    onClick={() =>
                      handleDownloadCdr(
                        "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Ftiranga_exhibition%2FHINDI.cdr?alt=media&token=ef631bca-03fb-4331-a4bd-2eadc0fea09e"
                      )
                    }
                  >
                    {t("download_open_hindi")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box sx={{ padding: { xs: "2%", md: "4%" } }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
        >
          {t("spirit_of_tiranga_videos")}
        </Typography>
        <Card>
          <Grid
            container
            spacing={4}
            sx={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fspirit_of_tiranga%2FAll%20state%20tiranga%2013%20AUG.mp4?alt=media&token=723a03ed-b3f4-4d1d-bbcf-0441882c137f",
              "/asset/images/spirit1.png",
              "",
              {
                english: "",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fspirit_of_tiranga%2FAll%20state%20tiranga%2013%20AUG.mp4?alt=media&token=723a03ed-b3f4-4d1d-bbcf-0441882c137f",
              },
              {
                english: "",
              }
            )}
            {bannerSection(
              "video",
              "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fspirit_of_tiranga%2FSpirit%20of%20Tiranga%2010%20Aug%20final.MP4?alt=media&token=22238a91-f385-4d3b-9d67-5cdea5154b6f",
              "/asset/images/spirit1.png",
              "",
              {
                english: "",
                hindi:
                  "https://firebasestorage.googleapis.com/v0/b/har-ghar-tiranga-2024.appspot.com/o/DownloadSection%2Fspirit_of_tiranga%2FSpirit%20of%20Tiranga%2010%20Aug%20final.MP4?alt=media&token=22238a91-f385-4d3b-9d67-5cdea5154b6f",
              },
              {
                english: "",
              }
            )}
          </Grid>
        </Card>
      </Box>
      <Footer imgUrl="./asset/footer/homeFooter.png" />
    </div>
  );
};

export default Downloads;

import React, { useState, useEffect } from "react";
import MediaGallery from "../../components/media-gallery";
import { MODERATED_PLEDGE_MEDIA } from "../../firebase/collectionConstants";
import { getMediaImages } from "../../api";
import { useInView } from "react-intersection-observer";

import Navbar from "../../layouts/full-layout/header/homeHeader";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Gallery() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();
  const [upref, upView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getImages(page);
  }, []);

  useEffect(() => {
    if (upView && !paginationLoading && more && !searchQuery) {
      getImages(page);
    }
  }, [upView, paginationLoading, more, page]);

  const getImages = async (pageNum, searchQuery) => {
    try {
      setPaginationLoading(true);
      if (pageNum === 1) setInitialLoading(true);
      const imagesData = await getMediaImages(
        MODERATED_PLEDGE_MEDIA,
        pageNum,
        false,
        10,
        searchQuery
      );
      const imageData = imagesData?.data?.data;

      if (imageData.length === 0) {
        setMore(false);
      } else {
        setImages((prevImages) => [...prevImages, ...imageData]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setPaginationLoading(false);
      setInitialLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchQuery.length !== 10) {
      setShowError(true);
      return;
    }
    setImages([]);
    getImages(1, searchQuery);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setImages([]);
    window.location.reload();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setSearchQuery(value);
    }
    if (value.length < 1) {
      clearSearch()
    }
  };

  return (
    <>
      <Navbar />
      <Box
        style={{
          width: "100%",
          height: "100%",
          marginTop: screenWidth > 600 ? "8%" : "22%",
        }}
      >
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          {t("media_gallery")}
        </Typography>
        {!initialLoading && (
          <Box sx={{ textAlign: "left", mb: 2, mt: 2, pl: 5 }}>
            <TextField
              label={t("enter_phone")}
              variant="outlined"
              value={searchQuery}
              onChange={handleChange}
              sx={{ mr: 1 }}
              size="small"
              error={showError}
              helperText={showError ? t("invalid_phone_number") : ""}
            />
            <Button
              variant="contained"
              onClick={images.length > 1 ? handleSearch : clearSearch}
            >
              {images.length > 1 && !initialLoading
                ? t("search")
                : t("clear_search")}
            </Button>
          </Box>
        )}
        {initialLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <MediaGallery images={images} />
        )}
        {images.length > 0 && (
          <div
            id="upref"
            ref={upref}
            className="text-center"
            style={{
              height: "50px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            {paginationLoading && <CircularProgress />}
          </div>
        )}
        <Snackbar
          open={showError}
          autoHideDuration={6000}
          onClose={() => setShowError(false)}
        >
          <Alert onClose={() => setShowError(false)} severity="error">
            {t("invalid_phone_number")}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

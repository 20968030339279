import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full-layout/loadable/Loadable";
import EventType from "../views/event-type";
import { element } from "prop-types";
import SalientFeature from "../views/flag-code/salient-feature";
import Faq from "../views/flag-code/faq";
import Steps from "../views/steps";
import Gallery from "../views/gallery";
import DownloadSelfie from "../views/download-selfie";
import About from "../views/about";
import Downloads from "../views/downloads";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import("../layouts/full-layout/FullLayout"))
);
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank-layout/BlankLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Login = Loadable(lazy(() => import("../views/authentication/Login")));
const ResetPassword = Loadable(
  lazy(() => import("../views/authentication/ResetPassword"))
);

/* ****Pages***** */
const MapContainer = Loadable(lazy(() => import("../views/events-map")));
const Home = Loadable(lazy(() => import("../views/home")));
const Pledge = Loadable(lazy(() => import("../views/pledge")));
const UploadSelfie = Loadable(lazy(() => import("../views/upload-selfie")));
const Certificate = Loadable(lazy(() => import("../views/certificate")));
const TermsAndConditions = Loadable(lazy(() => import("../views/terms")));
const PrivacyPolicy = Loadable(lazy(() => import("../views/privacy-policy")));

/* ****Routes***** */

const routes = [
  { path: "/", element: <Home /> },
  { path: "/steps", element: <Steps /> },
  { path: "/about", element: <About /> },
  // { path: "/pledge", element: <Pledge /> },
  { path: "/downloads", element: <Downloads /> },
  // { path: "/upload-selfie/:id", element: <UploadSelfie /> },
  // { path: "/download-selfie/:id", element: <DownloadSelfie /> },
  // { path: "/certificate/:id", element: <Certificate /> },
  { path: "/salient-features", element: <SalientFeature /> },
  { path: "/faq", element: <Faq /> },
  { path: "/gallery", element: <Gallery /> },
  { path: "terms-and-conditions", element: <TermsAndConditions /> },
  { path: "privacy-policy", element: <PrivacyPolicy /> },
  { path: "404", element: <Error /> },
  { path: "login", element: <Login /> },
  { path: "reset-password", element: <ResetPassword /> },
  { path: "*", element: <Navigate to="/auth/404" /> },
  {
    path: "auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default routes;

import React from "react";
import { Typography, Grid, Box, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import Navbar from "../../layouts/full-layout/header/homeHeader";
import { useNavigate } from "react-router-dom";
import Footer from "../../layouts/full-layout/footer/Footer";

export default function Steps() {
  const navigate = useNavigate();
  const screenSize = window.innerWidth;
  const { t } = useTranslation();
  const handleNavigatePledge = () => {
    navigate("/pledge");
  };
  return (
    <>
      <Navbar />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflowX: "hidden",
          position: "relative",
          marginTop: screenSize < 700 && "70px",
        }}
      >
        <Typography
          className="landing_section_title"
          variant="h1"
          sx={{ textAlign: "center", marginTop: "8%" }}
        >
          {/* Hoist Flag/Upload Selfie */}
          {t("steps_title")}
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ mt: 2, justifyContent: "center" }}>
        <Grid
          item
          // xs={6}
          // sm={6}
          // lg={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card className="step_card">
            <div className="">
              <p className="step_title">{t("step1")}</p>
            </div>
            <div className="step_input">{t("name")}</div>
            <div className="step_input">{t("mobile")}</div>
            <div className="step_input">{t("country_state")}</div>
            <div className="">
              <p className="step_footer">{t("enter_details")}</p>
            </div>
          </Card>
        </Grid>
        <Grid
          item
          // xs={6}
          // sm={6}
          // lg={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card className="step_card">
            <div className="">
              <p className="step_title">{t("step2")}</p>
            </div>
            <div className="step_img">
              <img src="./asset/images/step_flag.jpg" alt="" />
            </div>
            <div className="">
              <p className="step_footer">{t("click_picture_hosting")}</p>
            </div>
          </Card>
        </Grid>
        <Grid
          item
          // xs={6}
          // sm={6}
          // lg={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card className="step_card">
            <div className="">
              <p className="step_title">{t("step3")}</p>
            </div>
            <div className="step_box">
              <div>
                <img
                  style={{ height: "50px" }}
                  src="./asset/images/cloud.svg"
                  alt=""
                />
                <p className="drop">{t("drag_and_drop_files")}</p>
                <div className="or">{t("or")}</div>
              </div>
              <div className="d-flex">
                <div className="step_green_btn">{t("browse_files")}</div>
                <div className="step_orange_btn">{t("click_picture")}</div>
              </div>
            </div>
            <div className="">
              <p className="step_footer">{t("upload_media")}</p>
            </div>
          </Card>
        </Grid>
        {/* <Grid
          item
          xs={6}
          sm={6}
          lg={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="img-fluid"
            style={{ maxHeight: "350px" }}
            src={t("step_icon4")}
            alt="4"
          />
        </Grid> */}
      </Grid>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: "8%",
          marginTop: "3%",
        }}
      >
        <Typography className="flag_hoist_title">
          {t("click_to_participate")}
        </Typography>
        <button
          className="btn black_btn"
          onClick={() => {
            handleNavigatePledge();
          }}
        >
          {t("next")}
        </button>
      </Box>
      <Footer />
    </>
  );
}

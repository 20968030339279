import {
  USERS,
  PERMISSIONS,
  ROLES,
  COMPONENT,
  MINISTRIES,
  STATE_MASTER,
  DISTRICT_MASTER,
  ULB_MASTER,
  BLOCK_MASTER,
  EVENTS,
  MINISTRY_SUB_UNIT_MASTER,
  MINISTRY_UNIT_MASTER,
  USERS_DETAILS,
  EVENT_TYPE,
  CITY_REPORTS,
  PLEDGE_MEDIA,
  TWITTER_PUBLIC_FEED,
} from "./collectionConstants";
import firebase, { db } from "./index";
import {
  collection,
  getDocs,
  where,
  query,
  getDoc,
  doc,
  limit,
  startAfter,
  orderBy,
} from "firebase/firestore";

export const getCurrentToken = async () => {
  return await firebase.auth().currentUser.getIdToken();
};

export const getUserByID = async (id) => {
  const querySnapshot = await getDocs(
    query(collection(db, USERS), where("user_id", "==", id))
  );
  let userRecord;
  querySnapshot.forEach((doc) => {
    userRecord = doc.data();
    userRecord.doc_id = doc.id;
  });
  return userRecord;
};

export const getUserByUsername = async (id) => {
  const querySnapshot = await getDocs(
    query(collection(db, USERS_DETAILS), where("user_name", "==", id))
  );
  let userRecord;
  querySnapshot.forEach((doc) => {
    userRecord = doc.data();
    userRecord.doc_id = doc.id;
  });
  return userRecord;
};

export const getPermissionsByRoleID = async (roleID) => {
  const querySnapshot1 = await getDocs(
    query(collection(db, PERMISSIONS), where("role_id", "==", roleID))
  );
  let data;
  querySnapshot1.forEach((doc) => {
    data = doc.data();
    data.doc_id = doc.id;
  });
  return data;
};

export const getAllRoles = async () => {
  const querySnapshot = await getDocs(collection(db, ROLES));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllCityReports = async () => {
  const querySnapshot = await getDocs(collection(db, CITY_REPORTS));
  let report = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    report.push(newdata);
  });
  return report;
};

export const getAllComponents = async () => {
  const querySnapshot = await getDocs(collection(db, COMPONENT));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllUsers = async () => {
  const usersSnapshot = await getDocs(collection(db, USERS_DETAILS));
  let userData = [];
  usersSnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    userData.push(newdata);
  });
  return userData;
};

export const getAllMinistries = async () => {
  const querySnapshot = await getDocs(collection(db, MINISTRIES));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

// export const getAllEventCategories = async () => {
//   const querySnapshot = await getDocs(collection(db, Event_CATEGORY));
//   let data = [];
//   querySnapshot.forEach((doc) => {
//     let newdata = doc.data();
//     newdata.doc_id = doc.id;
//     data.push(newdata);
//   });
//   return data;
// };

export const getAllEventTypes = async () => {
  const querySnapshot = await getDocs(collection(db, EVENT_TYPE));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllStates = async () => {
  const querySnapshot = await getDocs(collection(db, STATE_MASTER));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getDistrictsByStateCode = async (stateCode) => {
  const querySnapshot = await getDocs(
    query(collection(db, DISTRICT_MASTER), where("state_code", "==", stateCode))
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getMinistryUnitByMinistryCode = async (ministryCode) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, MINISTRY_UNIT_MASTER),
      where("ministry_code", "==", ministryCode)
    )
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getMinistrySubUnitByMinistryUnitCode = async (
  ministryUnitCode
) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, MINISTRY_SUB_UNIT_MASTER),
      where("ministry_unit_code", "==", ministryUnitCode)
    )
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getULBsByDistrictCode = async (districtCode) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, ULB_MASTER),
      where("district_code", "==", districtCode)
    )
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getBlocksByDistrictCode = async (districtCode) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, BLOCK_MASTER),
      where("district_code", "==", districtCode)
    )
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getUserByUserName = async (userName) => {
  const querySnapshot = await getDocs(
    query(collection(db, USERS_DETAILS), where("user_name", "==", userName))
  );
  const data = [];
  querySnapshot.docs.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getUserByUserId = async (id) => {
  const querySnapshot = await getDocs(
    query(collection(db, USERS), where("user_name", "==", id))
  );
  let data;
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data = newdata;
  });
  return data;
};

export const getAllEvents = async () => {
  const querySnapshot = await getDocs(collection(db, EVENTS));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getEventByID = async (id) => {
  const docRef = doc(db, EVENTS, id);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};

export const getMediaImages = async (collectionName) => {
  const querySnapshot = await getDocs(
    collection(db, collectionName),
    limit(12)
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    if (newdata.moderated === true) {
      data.push(newdata);
    }
  });
  return data;
};

export const getPaginatedMediaImages = async (
  collectionName,
  lastVisible = null
) => {
  const collectionRef = collection(db, collectionName);
  let q = query(collectionRef, orderBy("uploaded_at", "desc"), limit(15));

  if (lastVisible) {
    q = query(
      collectionRef,
      orderBy("uploaded_at", "desc"),
      startAfter(lastVisible),
      limit(15)
    );
  }

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return {
    data,
    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
  };
};

export const getAllEventType = async () => {
  const querySnapshot = await getDocs(collection(db, EVENT_TYPE));
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

// export const getMapDetails = async (key) => {
//   const docRef = doc(
//     db,
//     "apiCache",
//     "cc7c65762d991a9e4d880f11e3817934710dbcd52716514b30f8d08085046817"
//   );
//   const docSnap = await getDoc(docRef);
//   return docSnap.data();
// };

export const fetchCount = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName);
    const snapshot = await getDocs(collectionRef);
    return snapshot.size;
  } catch (err) {
    console.error("Error:", err);
    return null;
  }
};

export const fetchUserNames = async (id) => {
  const docRef = doc(db, PLEDGE_MEDIA, id);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};

export const fetchTweetData = async () => {
  const q = query(collection(db, TWITTER_PUBLIC_FEED), limit(10));
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  // console.log("twitterdata", data);
  return data;
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logos/logo.png";
import Logo1 from "../../../assets/images/logos/logo1.png";
const LogoIcon1 = () => {
  return (
    <>
      <Link underline="none" to="/">
        <img src={Logo1} style={{ width: "100px" }} alt="LOGO" />
      </Link>
    </>
  );
};

const LogoIcon = () => {
  return (
    <>
      <Link underline="none" to="/">
        <img src={Logo} style={{ width: "35px" }} alt="LOGO" />
      </Link>
    </>
  );
};

const LanguageIcon = () => {
  return (
    <Link underline="none">
      <img
        src="/asset/logo/language.svg"
        style={{ width: "32px" }}
        alt="LOGO"
      />
    </Link>
  );
};

const Header = ({ sx }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [anchorElFlag, setAnchorElFlag] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFlagClick = (event) => {
    setAnchorElFlag(event.currentTarget);
  };

  const handleFlagClose = () => {
    setAnchorElFlag(null);
  };

  const handleLangClick = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleLangClose = () => {
    setAnchorElLang(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const languages = [
    { code: "en", label: "English" },
    { code: "hin", label: "हिन्दी" },
    { code: "guj", label: "Gujarati" },
    { code: "mal", label: "Malayalam" },
    { code: "od", label: "Odiya" },
    { code: "pun", label: "Punjabi" },
    { code: "tam", label: "Tamil" },
    { code: "tel", label: "Telugu" },
    { code: "as", label: "Assamese" },
    { code: "ben", label: "Bengali" },
    { code: "kan", label: "Kannada" },
    { code: "mar", label: "Marathi" },
  ];

  return (
    <AppBar
      sx={sx}
      elevation={0}
      style={{
        boxShadow: "0px 4px 33.8px 0px #00000033",
        backgroundColor: "white",
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              <ListItem button component={Link} to="/">
                <ListItemText primary={t("home")} />
              </ListItem>
              <ListItem button component={Link} to="/gallery">
                <ListItemText primary={t("selfie")} />
              </ListItem>
              <ListItem button component={Link} to="/about">
                <ListItemText primary={t("about")} />
              </ListItem>
              <ListItem button onClick={handleFlagClick}>
                <ListItemText primary={t("flag_code")} />
              </ListItem>
              <ListItem button component={Link} to="/downloads">
                <ListItemText primary={t("downloads")} />
              </ListItem>
              <Menu
                id="flag-menu"
                anchorEl={anchorElFlag}
                keepMounted
                open={Boolean(anchorElFlag)}
                onClose={handleFlagClose}
              >
                <MenuItem
                  key="salient-features"
                  onClick={() => {
                    navigate("/salient-features");
                  }}
                >
                  {t("salient_feature")}
                </MenuItem>
                <MenuItem
                  key="faq"
                  onClick={() => {
                    navigate("/faq");
                  }}
                >
                  {t("faq")}
                </MenuItem>
              </Menu>
              <ListItem button onClick={handleLangClick}>
                <LanguageIcon />
                <ListItemText primary={t("language")} />
              </ListItem>
              <Menu
                id="lang-menu"
                anchorEl={anchorElLang}
                keepMounted
                open={Boolean(anchorElLang)}
                onClose={handleLangClose}
              >
                {languages.map((lang) => (
                  <MenuItem
                    key={lang.code}
                    onClick={() => {
                      changeLanguage(lang.code);
                      setLanguage(lang.code);
                      handleLangClose();
                    }}
                  >
                    {lang.label}
                  </MenuItem>
                ))}
              </Menu>
            </List>
          </Box>
        </Drawer>
        <Box pl={3}>
          <LogoIcon1 />
        </Box>
        <Box flexGrow={0.45} />
        {!isMobile && (
          <>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/"
            >
              <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="500"
                sx={{ ml: 1, mr: 3 }}
              >
                {t("home")}
              </Typography>
            </Link>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/gallery"
            >
              <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="500"
                sx={{ ml: 1, mr: 3 }}
              >
                {t("selfie")}
              </Typography>
            </Link>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/about"
            >
              <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="500"
                sx={{ ml: 1, mr: 3 }}
              >
                {t("about")}
              </Typography>
            </Link>
            <div className="lang_div">
              <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="500"
                sx={{ ml: 1, mr: 3, cursor: "pointer" }}
                onClick={handleFlagClick}
              >
                {t("flag_code")}
              </Typography>
              <Menu
                id="flag-menu"
                anchorEl={anchorElFlag}
                keepMounted
                open={Boolean(anchorElFlag)}
                onClose={handleFlagClose}
              >
                <MenuItem
                  key="salient-features"
                  onClick={() => {
                    navigate("/salient-features");
                  }}
                >
                  {t("salient_feature")}
                </MenuItem>
                <MenuItem
                  key="faq"
                  onClick={() => {
                    navigate("/faq");
                  }}
                >
                  {t("faq")}
                </MenuItem>
              </Menu>
            </div>
            <div className="lang_div">
              <Link
                style={{
                  textDecoration: "none",
                }}
                to="/downloads"
              >
                <Typography
                  color="textSecondary"
                  variant="h5"
                  fontWeight="500"
                  sx={{ ml: 1, mr: 3 }}
                >
                  {t("downloads")}
                </Typography>
              </Link>
            </div>
            <div className="lang_div">
              <IconButton onClick={handleLangClick}>
                <LanguageIcon />
              </IconButton>
              <Menu
                id="lang-menu"
                anchorEl={anchorElLang}
                keepMounted
                open={Boolean(anchorElLang)}
                onClose={handleLangClose}
              >
                {languages.map((lang) => (
                  <MenuItem
                    key={lang.code}
                    onClick={() => {
                      changeLanguage(lang.code);
                      setLanguage(lang.code);
                      handleLangClose();
                    }}
                  >
                    {lang.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </>
        )}
        <Box flexGrow={0.5} />
        <LogoIcon />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
};

export default Header;

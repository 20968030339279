import { React, useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom/dist";
import { useTranslation, Trans } from "react-i18next";
import Navbar from "../../layouts/full-layout/header/homeHeader";
import Footer from "../../layouts/full-layout/footer/Footer";

function FAQ() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Container
        // className="child"
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${"./images/bg_img1.png"})`,
          backgroundRepeat: "no-repeat",
          fontFamily: "sans-serif",
          overflow: "hidden",
          // height: '80vh',
          // display: 'flex',
          justifyContent: "center",
        }}
      >
        <Row className="about-section align-items-center">
          <Col
            lg={12}
            sm={12}
            style={{
              paddingTop: screenSize < 970 ? "22%" : "10%",
              marginBottom: "5%",
            }}
          >
            <div className="about" style={{ paddingTop: "2%" }}>
              <h3 className="galary_header">{t("faq_title")}</h3>

              <br />
              <p className="galary_title p-2">{t("faq1")}</p>
              <p className="galary_text p-2">{t("faq2")}</p>
              <p className="galary_title p-2">{t("faq3")}</p>
              <p className="galary_text p-2">{t("faq4")}</p>
              <p className="galary_title p-2">{t("faq5")}</p>
              <p className="galary_text p-2">{t("faq6")}</p>
              <p className="galary_title p-2">{t("faq7")}</p>
              <p className="galary_text p-2">{t("faq8")}</p>
              <p className="galary_title p-2">{t("faq9")}</p>
              <p className="galary_text p-2">{t("faq10")}</p>
              <p className="galary_title p-2">{t("faq11")}</p>
              <p className="galary_text p-2">{t("faq12")}</p>
              <p className="galary_title p-2">{t("faq13")}</p>
              <p className="galary_text p-2">{t("faq14")}</p>
              <p className="galary_title p-2">{t("faq15")}</p>
              <p className="galary_text p-2">
                <ul>
                  <li>{t("faq16")}</li>
                  <li>{t("faq17")}</li>
                  <li>{t("faq18")}</li>
                  {/* <li>{t('faq181')}</li> */}
                  <li>{t("faq19")}</li>
                  <li>{t("faq20")}</li>
                  <li>{t("faq21")}</li>
                  <li>{t("faq22")}</li>
                  <li>{t("faq23")}</li>
                  <li>{t("faq24")}</li>
                  <li>{t("faq25")}</li>
                </ul>
              </p>
              <p className="galary_title p-2">{t("faq26")}</p>
              <p className="galary_text p-2">
                <ul>
                  <li>{t("faq27")}</li>
                  <li>{t("faq28")}</li>
                  <li>{t("faq29")} </li>
                  <li>{t("faq30")}</li>
                  <li>{t("faq31")}</li>
                  <li>{t("faq32")}</li>
                </ul>
              </p>
              <p className="galary_title p-2">{t("faq33")}</p>
              <p className="galary_text p-2">
                {/* As per Section III of Part III of the Flag Code of India, if
                  the National Flag is flown on public buildings, it should be
                  flown on all days from sunrise to sunset, irrespective of the
                  weather conditions. It should be hoisted briskly and lowered
                  slowly. */}
                <ul>
                  <li>{t("faq34")}</li>
                  <li>{t("faq35")}</li>
                </ul>
              </p>

              <p className="galary_title p-2">{t("faq36")}</p>
              <p className="galary_text p-2">{t("faq37")}</p>
              <p className="galary_title p-2">{t("faq38")}</p>
              <p className="galary_text p-2">
                {/* {t('faq38')} */}
                <ul>
                  <li>{t("faq39")}</li>
                  <li>{t("faq40")}</li>
                  <li>{t("faq41")}</li>
                  <li>{t("faq42")}</li>
                  <li>{t("faq43")}</li>
                  <li>{t("faq44")}</li>
                  <li>{t("faq45")}</li>
                  <li>{t("faq46")}</li>
                  <li>{t("faq47")}</li>
                  <li>{t("faq48")}</li>
                  <li>{t("faq49")}</li>
                  <li>{t("faq50")}</li>
                  <li>{t("faq51")}</li>
                </ul>
              </p>
              <p className="galary_title p-2">{t("faq52")}</p>
              <p className="galary_text p-2">
                <ul>
                  <li>{t("faq53")}</li>
                  <li>{t("faq54")}</li>
                  <li>{t("faq55")}</li>
                  <li>{t("faq56")}</li>
                </ul>
              </p>
              <p className="galary_title p-2">{t("faq57")}</p>
              <p className="galary_text p-2">
                <ul>
                  <li>{t("faq58")}</li>
                  <li>{t("faq59")}</li>
                </ul>
              </p>

              <p className="galary_text p-2 text-break">{t("faq60")}</p>
              <a
                className="galary_text p-2 text-break"
                href="https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf"
              >
                https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf
              </a>
              <br />
              <a
                className="galary_text p-2 text-break"
                href="https://www.mha.gov.in/sites/default/files/Prevention_Insults_National_Honour_Act1971_1.pdf "
              >
                https://www.mha.gov.in/sites/default/files/Prevention_Insults_National_Honour_Act1971_1.pdf{" "}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default FAQ;

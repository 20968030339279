import { React, useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router";
import Navbar from "../../layouts/full-layout/header/homeHeader";
import Footer from "../../layouts/full-layout/footer/Footer";

function SalientFeatures() {
  const { t, i18n } = useTranslation();
  const [screenSize, setScreenSize] = useState("");
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Container
        // className="child"
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${"./images/bg_img1.png"})`,
          backgroundRepeat: "no-repeat",
          fontFamily: "sans-serif",
          overflow: "hidden",
          // height: '80vh',
          // display: 'flex',
          justifyContent: "center",
        }}
      >
        <Row className="about-section align-items-center">
          <Col
            lg={12}
            sm={12}
            style={{
              paddingTop: screenSize < 970 ? "22%" : "10%",
              marginBottom: "5%",
            }}
          >
            <div className="about" style={{ paddingTop: "2%" }}>
              <h3 className="galary_header">{t("sf_title")}</h3>

              <p className="galary_text p-2">{t("sf1")}</p>
              <p className="galary_text p-2">{t("sf2")}</p>
              <p className="galary_text p-2">{t("sf3")}</p>
              <p className="galary_text p-2">{t("sf4")}</p>
              <p className="galary_text p-2">{t("sf5")}</p>
              <p className="galary_text p-2">{t("sf6")}</p>
              <p className="galary_text p-2">{t("sf7")}</p>
              <p className="galary_text p-2">{t("sf8")}</p>
              <p className="galary_text p-2">{t("sf9")}</p>
              <p className="galary_text p-2">{t("sf10")}</p>
              <p className="galary_text p-2">{t("sf11")}</p>
              <p className="galary_title p-2">{t("sf12")}</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SalientFeatures;
